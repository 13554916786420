<template>
        <defaultSec :title="'创单信息'">
            <el-button type="primary" @click="orderAdd" stylet="text-align:center;">立即保存</el-button>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form :model="form" class="demo-form-inline" label-width="82px" size="mini">
                        <el-form-item label="买家id">
                            <el-input v-model="form.buyerId" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="地址选择">
                            <el-cascader
                                placeholder="请选择省市区"
                                v-model="cityValue"
                                :options="addressCityList"
                                :props="{value:'Id',label:'Name',children:'VoList'}"
                                @change="handleCasChange"></el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址">
                            <el-input v-model="form.consigneeAddress"></el-input>
                        </el-form-item>
                        <el-form-item label="用户备注">
                            <el-input type="textarea" v-model="form.consigneeRemark" maxlength="200"
                                      autosize></el-input>
                        </el-form-item>

                        <!-- <el-form-item label="是否套装">
                            <el-select v-model="form.isPromotion" clearable  placeholder="是否套装">
                                <el-option v-for="item in arr" :label="item.name" :value="item.value" :key="item.value"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="添加商品">
                            <el-button type="danger" size="mini" @click="addProduct">添加商品</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="12">
                    <el-form :model="form" class="demo-form-inline" label-width="82px" size="mini">
                        <el-form-item label="平台">
                            <el-select v-model="form.shopId" clearable placeholder="选择店铺" size="mini">
                                <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收件人">
                            <el-input v-model="form.consigneeName"></el-input>
                        </el-form-item>
                        <el-form-item label="收件手机">
                            <el-input v-model="form.consigneeMobile" maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="配送日期">
                            <el-date-picker
                                v-model="form.deliveryTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions0">>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="客服备注">
                            <el-input type="textarea" v-model="form.remark" maxlength="200" autosize></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>

            <el-table :data="form.wxOrderProductVOList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <!--                <el-table-column label="是否套装">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-select v-model="scope.row.isPromotion" clearable  placeholder="是否套装" @change="changeProduct">-->
                <!--                            <el-option v-for="item in arr" :label="item.name" :value="item.value" :key="item.value"></el-option>-->
                <!--                        </el-select>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column label="商品选择">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.cbarcode" clearable @change="selectProduct($event,scope.row)" placeholder="选择商品" filterable size="mini">
                            <el-option
                                v-for="item in productList"
                                :key="item.cbarcode"
                                :label="item.value + '(' + item.cbarcode + ')'"
                                :value="item.cbarcode">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="数量">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.productNum"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" icon="el-icon-delete"
                                   @click="delProduct(scope.$index)" circle></el-button>
                    </template>
                </el-table-column>

            </el-table>
        </defaultSec>
</template>


<script>
import defaultSec from '@c/defaultSection'

export default {
    data() {
        return {
            addressCityList: [],
            productList: [],
            shopList : [],
            cityValue: '',
            arr: [{name: '否', value: 0}, {name: '是', value: 1}],
            form: {
                shopId : '',
                isPromotion: 0,
                buyerId: 'csms-manual-order-creator',
                consigneeName: '',
                addressCityList: [],
                consigneeMobile: '',
                consigneeAddress: '',
                deliveryTime: null,
                consigneeRemark: '',
                remark: '',
                consigneeAreaid: null,
                productList: [],
                wxOrderProductVOList: []
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的
                }
            }
        }
    },
    components: {
        defaultSec
    },
    mounted() {
        //获取套装添加单品下拉列表
        this.getShopList();
        this.getBDProducts();
        this.getAddressCityList();
    },
    methods: {
        getAddressCityList() {
            this.$apiGet('lechun-customers/customer/getProvinceCityAreaByErp', {
                transportType: -1,
                isLongPeriodBuy: 0
            }).then(res => {
                console.log(res)
                let add = res.provinceVoList
                let newArr = add.map(item => {
                    item = this.setCityKey(item)
                    item.VoList = item.VoList.map(item1 => {
                        item1 = this.setCityKey(item1)
                        if (item1.VoList != null) {
                            item1.VoList = item1.VoList.map(item2 => {
                                item2 = this.setCityKey(item2)
                                return item2
                            })
                        }
                        return item1
                    })
                    return item
                })
                this.addressCityList = newArr
            });
        },
        getBDProducts() {
            this.$apiGet('lechun-csms/bdProduct/getProducts').then(res => {
                console.log("bdProduct/getProducts",res);
                this.productList = res;
            });
        },
        getShopList() {
            this.$apiGet('lechun-csms/shop/shopList').then(res => {
                console.log("shop/shopList",res);
                this.shopList = res;
            });
        },
        setCityKey(obj) {
            let newObj = {}
            let reg = /Name|Id|VoList/
            for (let key in obj) {
                if (reg.test(key)) {
                    newObj[key.match(reg)[0]] = obj[key]
                }
            }
            return newObj
        },
        handleCasChange(val) {
            this.form.consigneeAreaid = val.length >= 3 ? val[2] : '';
        },
        //TODO:1.购买者信息；2：产品信息；拼装格式为：{buyerId:"",consigneeName:"",orderProductParamDtoList:[{productId:"",productName:"",productNum:""},{productId:"",productName:"",productNum:""}]}
        orderAdd() {
            // if (this.form.consigneeMobile.length != 11) {
            //     this.$notify({
            //         title: '警告',
            //         message: '请输入正确的手机号',
            //         type: 'warning'
            //     });
            //     return false
            // }
            var formJson = {


                buyerId: this.form.buyerId,
                consigneeName: this.form.consigneeName,
                consigneeMobile: this.form.consigneeMobile,
                consigneeAddress: this.form.consigneeAddress,
                consigneeRemark: this.form.consigneeRemark,
                deliveryTime: this.form.deliveryTime,
                remark: this.form.remark,
                consigneeAreaid: this.form.consigneeAreaid,
                orderProductParamDtoList: this.form.wxOrderProductVOList,
                orderCreateMethod: 2
            };
            console.log(JSON.stringify(formJson));
            this.$apiGet('lechun-csms/orderApi/createOrder', {orderParam: JSON.stringify(formJson)}).then(res => {
                console.log(res)
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                //跳转到订单列表页
                var queryFormJson = {
                    pageSize: 15,
                    currentPage: 1,
                    externalOrderNo: '',
                    externalMainOrderNo: '',
                    sourceOrderMainNo: '',
                    consigneeMobile: '',
                    consigneeName: '',
                    shopName: '微信商城',
                    buyerId: ''
                };
                this.$router.push({path: '/orderList', name: 'orderList', params: {}})
            })
        },
        selectProduct(event,row){
            console.log("event",event);
            this.productList.forEach((pro) => {
                if(pro.cbarcode == event){
                    row.productId = pro.cguid;
                    row.productName = pro.cname;
                    row.sellPrice = 0;
                    row.iservice = pro.iservice;
                }
            });
            console.log("wxOrderProductVOList",this.form.wxOrderProductVOList);
        },
        addProduct() {
            this.form.wxOrderProductVOList.push({
                productId : '',
                productName : '',
                cbarcode: '',
                productNum: 1,
                sellPrice : 0,
                isPromotion: 0,
                iservice: 0
            })
        },
        delProduct(index) {
            console.log(index)
            this.form.wxOrderProductVOList.splice(index, 1)
        }
    }
}
</script>

<style scoped>
</style>